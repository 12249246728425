import { Typography } from "@mui/material";
import { ChatMessage, Project } from "@stai/types";
import { FC, Fragment } from "react";
import { StorageFile } from "../../Common/types/StorageFile";
import { ChatAttachmentAudioView } from "./ChatAttachmentAudioView";
import { ChatAttachmentDocumentView } from "./ChatAttachmentDocumentView";
import { ChatAttachmentImageView } from "./ChatAttachmentImageView";
import { ChatAttachmentVideoView } from "./ChatAttachmentVideoView";
import { ChatMessageLayoutView } from "./ChatMessageLayoutView";

interface Props {
  chatId?: string;
  chatMessage: ChatMessage;
  project?: Project;
  projectProfilePicture?: StorageFile;
  isLastMessage?: boolean;
}

export const ChatMessageView: FC<Props> = (props) => {
  const { chatId, chatMessage, project, projectProfilePicture, isLastMessage } =
    props;

  const isChatMessageTextVisible = !chatMessage.attachments
    ?.map((a) => a.type)
    .includes("AUDIO");

  return (
    <ChatMessageLayoutView
      senderType={chatMessage.senderType}
      profilePicture={projectProfilePicture}
      fullWidth={!!chatMessage.attachments?.length}
    >
      {chatMessage.attachments?.map((attachment, index) => (
        <Fragment key={index}>
          {attachment.type === "IMAGE" && (
            <ChatAttachmentImageView
              chatId={chatId}
              chatMessageId={chatMessage.id}
              project={project}
              attachment={attachment}
              attachmentIndex={index}
            />
          )}
          {attachment.type === "AUDIO" && (
            <ChatAttachmentAudioView
              chatId={chatId}
              chatMessageId={chatMessage.id}
              project={project}
              attachment={attachment}
              attachmentIndex={index}
            />
          )}
          {attachment.type === "VIDEO" && (
            <ChatAttachmentVideoView
              chatId={chatId}
              chatMessageId={chatMessage.id}
              project={project}
              attachment={attachment}
              attachmentIndex={index}
            />
          )}
         {attachment.type === "DOCUMENT" && (
            <ChatAttachmentDocumentView
              chatId={chatId}
              chatMessageId={chatMessage.id}
              project={project}
              attachment={attachment}
              attachmentIndex={index}
            />
          )}
        </Fragment>
      ))}
      {isChatMessageTextVisible && (
        <Typography variant="body2">{chatMessage.text}</Typography>
      )}
    </ChatMessageLayoutView>
  );
};
