import { useEffect, useMemo } from "react";
import { useChatMessageVisibility } from "./useChatMessageVisibility";
import { useChatMessagesByChatId } from "./useChatMessagesByChatId";
import { useLocalChatMessages } from "./useLocalChatMessages";

/**
 * This hook combines all chat messages:
 *
 * Server: chat messages loaded from Firestore
 * Local: chat messages that have just been sent by the user, and are not yet saved to Firestore
 * Initial: very first chat messages that are shown, when a `user` and `chat` have not been created yet
 */
export function useChatMessages(
  projectId: string,
  chatId: string
): ReturnType<typeof useChatMessagesByChatId> {
  const [serverChatMessages, ...rest] = useChatMessagesByChatId(
    projectId,
    chatId
  );
  const localChatMessages = useLocalChatMessages(
    (state) => state.localChatMessages
  );

  const chatMessages = useMemo(() => {
    // const serverLocalIds = serverChatMessages.map((m) => m.localId);
    // const uniqueLocalChatMessages = localChatMessages.filter(
    //   (l) => !serverLocalIds.includes(l.id)
    // );

    // if (localChatMessages.length !== uniqueLocalChatMessages.length) {
    //   setTimeout(() => {
    //     useLocalChatMessages.setState({
    //       localChatMessages: uniqueLocalChatMessages,
    //     });
    //   });
    // }

    const { isHidden } = useChatMessageVisibility.getState();

    return serverChatMessages.filter((m) => !isHidden(m.id));
  }, [serverChatMessages, localChatMessages]);

  useEffect(() => {
    return () => {
      useLocalChatMessages.getState().reset();
      useChatMessageVisibility.getState().reset();
    };
  }, []);

  return [chatMessages, ...rest];
}
