import { Box, Skeleton, Stack } from "@mui/material";
import { useStorageFile } from "@stai/common";
import { Attachment, Project } from "@stai/types";
import { FC } from "react";

interface Props {
  chatId?: string;
  chatMessageId: string;
  project?: Project;
  attachment: Attachment;
  attachmentIndex: number;
}

export const ChatAttachmentImageView: FC<Props> = (props) => {
  const { project, attachment, chatId } = props;
  const { url } = useStorageFile(attachment.filePath);

  if (!project) return null;
  if (attachment.type !== "IMAGE") return null;

  const aspectRatio = attachment.dimensions
    ? attachment.dimensions.width / attachment.dimensions.height
    : undefined;

  return (
    <Stack overflow="hidden" borderRadius={2} position="relative">
      {!url && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="auto"
          sx={{ aspectRatio }}
        />
      )}

      {url && (
        <Box
          component="img"
          src={url}
          alt="Unknown"
          width="100%"
          height="auto"
          sx={{
            aspectRatio,
            filter: "none",
          }}
        />
      )}
    </Stack>
  );
};
