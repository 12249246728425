import { Box, IconButton, Skeleton, Stack } from "@mui/material";
import { useStorageFile } from "@stai/common";
import { Attachment, Project } from "@stai/types";
import { FC, useRef, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

interface Props {
  chatId?: string;
  chatMessageId: string;
  project?: Project;
  attachment: Attachment;
  attachmentIndex: number;
}

export const ChatAttachmentVideoView: FC<Props> = (props) => {
  const { project, attachment, chatId } = props;
  const { url, isLoading } = useStorageFile(attachment.filePath);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  if (!project) return null;
  if (attachment.type !== "VIDEO") return null;

  const aspectRatio = attachment.dimensions
    ? attachment.dimensions.width / attachment.dimensions.height
    : undefined;

  const handlePlayClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Stack
      overflow="hidden"
      borderRadius={2}
      position="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isLoading && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="auto"
          sx={{ aspectRatio }}
        />
      )}

      {url && (
        <>
          <Box
            component="video"
            src={url}
            ref={videoRef}
            width="100%"
            height="auto"
            sx={{
              aspectRatio,
              filter: "none",
            }}
          />
          {isHovered && (
            <IconButton
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
              }}
              onClick={handlePlayClick}
            >
              {isPlaying ? (
                <PauseIcon fontSize="large" />
              ) : (
                <PlayArrowIcon fontSize="large" />
              )}
            </IconButton>
          )}
        </>
      )}
    </Stack>
  );
};