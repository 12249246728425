import { Box, IconButton, Skeleton, Stack } from "@mui/material";
import { useStorageFile } from "@stai/common";
import { Attachment, Project } from "@stai/types";
import { FC } from "react";
import DescriptionIcon from "@mui/icons-material/Description";

interface Props {
  chatId?: string;
  chatMessageId: string;
  project?: Project;
  attachment: Attachment;
  attachmentIndex: number;
}

export const ChatAttachmentDocumentView: FC<Props> = (props) => {
  const { project, attachment, chatId } = props;
  const { url, isLoading } = useStorageFile(attachment.filePath);

  if (!project) return null;
  if (attachment.type !== "DOCUMENT") return null;

  const handleDownloadClick = () => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.download = attachment.filePath || "document";
      link.click();
    }
  };

  return (
    <Stack alignItems="center" justifyContent="center">
      {isLoading && (
        <Skeleton variant="rectangular" width={40} height={40} />
      )}

      {url && (
        <IconButton
          sx={{
            width: 40,
            height: 40,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            color: "rgba(0, 0, 0, 0.6)",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          }}
          onClick={handleDownloadClick}
        >
          <DescriptionIcon />
        </IconButton>
      )}
    </Stack>
  );
};