import { Avatar, Stack } from "@mui/material";
import { FC, PropsWithChildren, ReactNode } from "react";
import { StorageFile } from "../../Common/types/StorageFile";

const AVATAR_SIZE = 32;

interface Props extends PropsWithChildren {
  senderType: "USER" | "AI" | "MANAGER";
  profilePicture?: StorageFile;
  actions?: ReactNode;
  fullWidth?: boolean;
}

export const ChatMessageLayoutView: FC<Props> = (props) => {
  const { senderType, profilePicture, children, actions, fullWidth } = props;

  const isManagerOrAi = senderType === "MANAGER" || senderType === "AI";

  return (
    <Stack
      px={1}
      direction={isManagerOrAi ? "row" : "row-reverse"}
      justifyContent="flex-start"
      spacing={1}
    >
      {isManagerOrAi && (
        <Avatar
          src={profilePicture?.url}
          sx={{
            border: "2px solid white",
            width: AVATAR_SIZE,
            height: AVATAR_SIZE,
          }}
        />
      )}
      <Stack maxWidth="80%" width={fullWidth ? "100%" : undefined}>
        {!!children && (
          <Stack
            p={1.5}
            bgcolor={isManagerOrAi ? "grey.500" : "grey.700"}
            borderRadius="18px"
            border="1px solid"
            borderColor="grey.400"
            position="relative"
            spacing={1}
          >
            {children}
          </Stack>
        )}
        {actions}
      </Stack>
    </Stack>
  );
};
