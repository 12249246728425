import { Stack } from "@mui/material";
import { Attachment, Project } from "@stai/types";
import { FC, useEffect } from "react";
import { AudioPlayButton } from "./AudioPlayButton";
import { AudioSkeleton } from "./AudioSkeleton";
import { toAttachmentId } from "./toAttachmentId";
import { useAudioPlayer } from "./useAudioPlayer";

interface Props {
  chatId?: string;
  chatMessageId: string;
  project?: Project;
  attachment: Attachment;
  attachmentIndex: number;
}

export const ChatAttachmentAudioView: FC<Props> = (props) => {
  const { attachment, chatMessageId, attachmentIndex, chatId } = props;
  const { filePath } = attachment;
  const attachmentId = toAttachmentId(chatMessageId, attachmentIndex);
  const isInitialized = useAudioPlayer(
    (state) => state.isInitializedRecord[attachmentId]
  );

  useEffect(() => {
    async function startEffect() {
      if (!filePath) return;
      await useAudioPlayer.getState().downloadUrl(attachmentId, filePath);
      await useAudioPlayer.getState().initPlayer(attachmentId);
      if (attachmentId === useAudioPlayer.getState().startedAttachmentId) {
        await useAudioPlayer.getState().play(attachmentId);
      }
    }
    void startEffect();
    return () => {
      useAudioPlayer.getState().destroy(attachmentId);
    };
  }, [attachmentId, filePath]);

  return (
    <Stack direction="row" spacing={2} flex={1} alignItems="center">
      <AudioPlayButton
        attachmentId={attachmentId}
        filePath={filePath}
      />
      <Stack flex={1} justifyContent="center" position="relative">
        {!isInitialized && (
          <AudioSkeleton sx={{ position: "absolute", left: 0, right: 0 }} />
        )}
        <div
          style={{ visibility: isInitialized ? "visible" : "hidden" }}
          id={attachmentId}
        />
      </Stack>
    </Stack>
  );
};
