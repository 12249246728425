import MailIcon from "@mui/icons-material/Mail";
import {
  Button,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { MainPage } from "../../Main/views/MainPage";

export const HomePage: FC = () => {
  return (
    <MainPage>
      <Stack justifyContent="center" flex={1} spacing={4}>
        <Typography variant="h1" textAlign="center">
          Stai
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Tooltip title="Contact" placement="top">
            <IconButton href="mailto:info@stai.chat">
              <MailIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </MainPage>
  );
};

const SButton = styled(Button)(({ theme }) => ({
  minWidth: 220,
  color: theme.palette.common.white,
  borderColor: theme.palette.common.white,
}));
