import { Avatar, Skeleton, Stack, styled, Typography } from "@mui/material";
import { Chat, Project } from "@stai/types";
import { FC } from "react";
import { StorageFile } from "../../Common/types/StorageFile";
import { MainContainer } from "../../Main/views/MainContainer";

const CHAT_HEADER_HEIGHT = "160px";

interface Props {
  project?: Project;
  chat?: Chat;
  profilePicture?: StorageFile;
}

export const ChatHeadView: FC<Props> = (props) => {
  const { project, chat, profilePicture } = props;
  const isProfilePictureVisible = project && !profilePicture?.isLoading;

  return (
    <>
      <Stack position="fixed" top={0} left={0} right={0} zIndex={5}>
        <MainContainer>
          <SChatHead direction="row" spacing={2}>
            <Stack>
              {!isProfilePictureVisible && (
                <Skeleton
                  variant="circular"
                  width={AVATAR_SIZE}
                  height={AVATAR_SIZE}
                />
              )}
              {isProfilePictureVisible && (
                <Avatar
                  src={profilePicture?.url}
                  sx={{
                    bgcolor: "primary.main",
                    width: AVATAR_SIZE,
                    height: AVATAR_SIZE,
                  }}
                />
              )}
            </Stack>
            <Stack flex={1} justifyContent="center">
              {!project && (
                <>
                  <Skeleton variant="text" width="50%" />
                  <Skeleton variant="text" width="30%" />
                </>
              )}
              {project && <Typography variant="h2">{project.name}</Typography>}
            </Stack>
            {/* <Stack justifyContent="center">
              <ChatHeadMenuButton project={project} chat={chat} />
            </Stack> */}
          </SChatHead>
        </MainContainer>
      </Stack>
      <Stack height={CHAT_HEADER_HEIGHT} />
    </>
  );
};

const SChatHead = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(6),
  backdropFilter: "blur(0.5px)",
  background:
    "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.95) 55%, rgba(0,0,0,0) 100%)",
}));

const AVATAR_SIZE = 96;
