import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { ChatPage } from "../../Chat/views/ChatPage";
import { HomePage } from "../../Home/views/HomePage";
import { CreateChatPage } from "../../Chat/views/CreateChatPage";

export const MainRoutes: FC = () => {
  return (
    <>
      <Routes>
        <Route index element={<HomePage />} />
        <Route
          path="/projects/:projectId"
          element={<CreateChatPage />}
        />
        <Route
          path="/projects/:projectId/stays/:stayId"
          element={<CreateChatPage />}
        />
        <Route
          path="/projects/:projectId/chats/:chatId"
          element={<ChatPage />}
        />
      </Routes>
      {/* <FirebaseAuthDialog /> */}
    </>
  );
};
