import { zodResolver } from "@hookform/resolvers/zod";
import SendIcon from "@mui/icons-material/Send";
import { IconButton, Stack, styled } from "@mui/material";
import { createCallable, useIsAnonymous, useMyUserRecord } from "@stai/common";
import { Project, ReceiveWebMessageDefinition } from "@stai/types";
import { Timestamp } from "firebase/firestore";
import { nanoid } from "nanoid";
import { FC, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { MainContainer } from "../../Main/views/MainContainer";
import { useAsyncChat } from "../hooks/useAsyncChat";
import { useLocalChatMessages } from "../hooks/useLocalChatMessages";

const CHAT_FOOTER_HEIGHT = "72px";

const sendChatMessageCallable = createCallable(ReceiveWebMessageDefinition);

const FormValues = z.object({
  text: z.string(),
});

export type FormValues = z.infer<typeof FormValues>;

interface Props {
  project?: Project;
}

export const ChatInputView: FC<Props> = (props) => {
  const { project } = props;
  const isAnonymous = useIsAnonymous();
  const userId = useMyUserRecord((state) => state.user?.id);
  const inputRef = useRef<HTMLInputElement>();
  const { control, handleSubmit, setValue } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: { text: "" },
  });

  const onSubmit = useCallback(

    async (formValues: FormValues) => {
      try {
        const text = formValues.text.trim();

        if (!text) return;
        if (!project) return;

        setValue("text", "");
        inputRef.current?.focus();
        const localId = nanoid();
        useLocalChatMessages.getState().add({
          id: localId,
          text,
          senderType: "USER",
          createdAt: Timestamp.now(),
        });

        const chat = await useAsyncChat.getState().getChat();

        await sendChatMessageCallable({
          projectId: project.id,
          chatId: chat.id,
          text,
        });
      } catch (error) {
        console.error(error);
        handleError(error);
        useLocalChatMessages.getState().reset();
      }
    },
    [setValue, userId, isAnonymous, project]
  );

  return (
    <>
      <Stack position="fixed" bottom={0} left={0} right={0} zIndex={5}>
        <MainContainer>
          <Stack
            position="relative"
            zIndex={1}
            bgcolor="grey.700"
            direction="row"
            alignItems="center"
            p={1}
            spacing={1}
          >
            <Stack
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              direction="row"
              bgcolor="background.default"
              borderRadius={100}
              flex={1}
            >
              <ControlledTextField
                name="text"
                control={control}
                inputRef={inputRef}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                placeholder="Ask"
                autoComplete="off"
                disabled={!project}
                fullWidth
              />
              <Stack justifyContent="center" pr={0.5}>
                <SIconButton type="submit">
                  <SendIcon />
                </SIconButton>
              </Stack>
            </Stack>
          </Stack>
        </MainContainer>
      </Stack>
      <Stack height={CHAT_FOOTER_HEIGHT} />
    </>
  );
};

const SIconButton = styled(IconButton)(({ theme }) => ({
  width: 48,
  height: 48,
  borderColor: theme.palette.grey[400],
  borderStyle: "solid",
  backgroundColor: theme.palette.grey[500],

  "@media(hover: hover)": {
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));
