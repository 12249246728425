import { LinkProps } from "@mui/material/Link";
import { createTheme, lighten } from "@mui/material/styles";
import { Bebas_Neue, Manrope } from "next/font/google";
import { forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { MAIN_PALETTE } from "./MAIN_PALETTE";

const BEBAS_NEUE = Bebas_Neue({
  weight: ["400"],
  subsets: ["latin"],
});

const MANROPE = Manrope({
  weight: ["400", "500", "600", "700"],
  subsets: ["latin"],
});

const defaultFontConfig = {
  fontSize: 14,
  lineHeight: 1.35,
};

/**
 * @see https://mui.com/material-ui/guides/routing/#global-theme-link
 */
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});

LinkBehavior.displayName = "LinkBehavior";

export const MAIN_THEME = createTheme({
  palette: MAIN_PALETTE,
  typography: {
    allVariants: {
      fontFamily: MANROPE.style.fontFamily,
    },
    h1: {
      fontFamily: BEBAS_NEUE.style.fontFamily,
    },
    h2: {
      fontFamily: BEBAS_NEUE.style.fontFamily,
      fontSize: 30,
    },
    h3: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "125%",
    },
    h4: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "140%",
    },
    h5: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "150%",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%",
        },
        body: {
          height: "100%",
          backgroundColor: MAIN_PALETTE.background.default,
          ...defaultFontConfig,
        },
        "#__next": {
          height: "100%",
        },
      },
    },
    // credits: https://mui.com/material-ui/guides/routing/#global-theme-link
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            color: MAIN_PALETTE.common.black,
            backgroundColor: MAIN_PALETTE.common.white,
            "&:hover": {
              backgroundColor: lighten(MAIN_PALETTE.primary.main, 0.6),
            },
          },
        },
        {
          props: { variant: "text", color: "primary" },
          style: {
            color: MAIN_PALETTE.common.white,
          },
        },
      ],
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
          "&&": {
            textTransform: "inherit",
          },
        },
        sizeLarge: {
          height: 56,
          fontWeight: "bold",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: `12px 16px`,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: MANROPE.style.fontFamily,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: BEBAS_NEUE.style.fontFamily,
          fontSize: "50px",
          fontWeight: "400",
          padding: "40px 32px 8px 32px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "32px",
          boxSizing: "border-box",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          borderTop: `1px solid ${MAIN_PALETTE.divider}`,
          padding: `16px 32px`,
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: "wave",
      },
    },
  },
});
