import { getDownloadURL, ref } from "firebase/storage";
import WaveSurfer from "wavesurfer.js/src/wavesurfer";
import { create } from "zustand";
import { MAIN_PALETTE } from "../../../Main/consts/MAIN_PALETTE";
import {
  AUDIO_PLAYER_BAR_SPACING,
  AUDIO_PLAYER_BAR_WIDTH,
  AUDIO_PLAYER_SIZE,
} from "./AUDIO_PLAYER";
import { storage } from "@stai/common";

interface State {
  startedAttachmentId?: string;
  isPlaying: boolean;
  downloadUrl: (attachmentId: string, filePath: string) => Promise<void>;
  initPlayer: (attachmentId: string) => Promise<void>;
  play: (attachmentId: string) => Promise<void>;
  pause: () => void;
  stop: () => void;
  destroy: (attachmentId: string) => void;
  /**
   * key is `attachmentId`, value is `hasStartedDownload`
   */
  hasStartedDownloadRecord: Record<string, boolean>;
  /**
   * key is `attachmentId`, value is `isInitialized`
   */
  isInitializedRecord: Record<string, boolean>;
  /**
   * key is `attachmentId`, value is `url`
   */
  urlRecord: Record<string, string>;
  /**
   * key is `attachmentId`, value is `WaveSurfer`
   */
  playerRecord: Record<string, WaveSurfer>;
}

export const useAudioPlayer = create<State>((set, get) => ({
  isPlaying: false,
  urlRecord: {},
  playerRecord: {},
  hasStartedDownloadRecord: {},
  isInitializedRecord: {},
  downloadUrl: async (attachmentId, filePath) => {
    get().hasStartedDownloadRecord[attachmentId] = true;
    const pathRef = ref(storage, filePath);
    const url = await getDownloadURL(pathRef).catch((error) => {
      get().hasStartedDownloadRecord[attachmentId] = false;
      throw error;
    });

    set((state) => ({
      urlRecord: { ...state.urlRecord, [attachmentId]: url },
    }));
  },
  initPlayer: async (attachmentId) => {
    // A direct import doesn't work here, because of SSR.
    // Read more: https://github.com/wavesurfer-js/wavesurfer.js/issues/2350
    const WaveSurfer = (await import("wavesurfer.js")).default;

    return new Promise((resolve, reject) => {
      const src = get().urlRecord[attachmentId];
      if (!src) return;
      const player = WaveSurfer.create({
        backend: "MediaElement",
        container: `[id='${attachmentId}']`,
        waveColor: "#9A9A9A",
        height: AUDIO_PLAYER_SIZE,
        cursorWidth: 2,
        normalize: true,
        barWidth: AUDIO_PLAYER_BAR_WIDTH,
        barRadius: AUDIO_PLAYER_BAR_WIDTH,
        barGap: AUDIO_PLAYER_BAR_SPACING,
        cursorColor: MAIN_PALETTE.common.white,
        progressColor: MAIN_PALETTE.primary.main,
      });

      player.on("ready", () => {
        set((state) => ({
          isInitializedRecord: {
            ...state.isInitializedRecord,
            [attachmentId]: true,
          },
        }));
        resolve();
      });

      player.on("error", reject);
      player.on("finish", get().stop);
      player.load(src);

      get().playerRecord[attachmentId] = player;
    });
  },
  play: async (attachmentId) => {
    const player = get().playerRecord[attachmentId];
    if (!player) return;

    const isInitialized = get().isInitializedRecord[attachmentId];
    if (!isInitialized) return;

    set({
      isPlaying: true,
      startedAttachmentId: attachmentId,
    });
    await player.play();
  },
  pause: () => {
    const startedAttachmentId = get().startedAttachmentId;

    set({
      isPlaying: false,
      startedAttachmentId: undefined,
    });

    if (!startedAttachmentId) return;
    const playingPlayer = get().playerRecord[startedAttachmentId];
    if (!playingPlayer) return;

    playingPlayer.pause();
  },
  stop: () => {
    const startedAttachmentId = get().startedAttachmentId;

    set({
      isPlaying: false,
      startedAttachmentId: undefined,
    });

    if (!startedAttachmentId) return;
    const playingPlayer = get().playerRecord[startedAttachmentId];
    if (!playingPlayer) return;

    playingPlayer.stop();
    playingPlayer.setCurrentTime(0);
  },
  destroy: (attachmentId) => {
    const player = get().playerRecord[attachmentId];
    if (!player) return;

    player.destroy();
    delete get().playerRecord[attachmentId];
    delete get().isInitializedRecord[attachmentId];
  },
}));
