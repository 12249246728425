import { auth, useMyUserRecord } from "@stai/common";
import { signInAnonymously } from "firebase/auth";
import { FC, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useMainInit } from "../hooks/useMainInit";

export const MainInitView: FC = () => {
  const [authState, isInitializingAuthState] = useAuthState(auth);
  const userId = authState?.uid;

  useMainInit.getState().onAuthState(authState, isInitializingAuthState);

  // Dependency: userId, isInitializingAuthState, customToken, navigate
  useEffect(() => {
    if (isInitializingAuthState) return;

    if (userId) return;

    signInAnonymously(auth)
      .then((auth) => console.log("signed in anonymously"))
      .catch((error: Error) => {
        useMainInit.setState({ error });
      });
  }, [userId, isInitializingAuthState]);

  // Dependency: userId
  useEffect(() => {
    if (userId) {
      console.log("Subscribe to user", userId);
      useMyUserRecord.getState().subscribe(userId);
    }

    return () => {
      useMyUserRecord.getState().unsubscribe?.();
    };
  }, [userId]);

  const isInitializing = isInitializingAuthState;

  useEffect(() => {
    useMainInit.setState({ isInitializing });
  }, [isInitializing]);

  return null;
};
