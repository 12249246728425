import { useStorageFile } from "@stai/common";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingPage } from "../../Common/views/LoadingPage";
import { LoadingView } from "../../Common/views/LoadingView";
import { MainPage } from "../../Main/views/MainPage";
import { useChat } from "../../Project/hooks/useChat";
import { useProject } from "../../Project/hooks/useProject";
import { useAsyncChat } from "../hooks/useAsyncChat";
import { ChatHeadView } from "./ChatHeadView";
import { ChatInputView } from "./ChatInputView";
import { ChatMessageListView } from "./ChatMessageListView";

export const ChatPage: FC = () => {
  const { projectId, chatId } = useParams<{
    projectId: string;
    chatId: string;
  }>();

  const [project, isProjectLoading, projectError] = useProject(projectId);

  console.log("projectId", projectId);
  console.log("chatId", chatId);

  const profilePicture = useStorageFile(project?.profilePictureFilePath);
  const [chat, isChatLoading, chatError] = useChat(projectId, chatId);
  useAsyncChat.getState().setChat(chat);
  console.log("chat", chat, isChatLoading, chatError);

  if (isProjectLoading) return <LoadingPage />;
  if (isChatLoading) return <LoadingPage />;
  if (projectError) return <ErrorPage error={projectError} />;
  if (chatError) return <ErrorPage error={chatError} />;

  const isLoading = isChatLoading || isProjectLoading;

  return (
    <MainPage>
      <ChatHeadView
        project={project}
        chat={chat}
        profilePicture={profilePicture}
      />
      {isLoading && <LoadingView />}
      {!isLoading && (
        <ChatMessageListView
          project={project}
          chat={chat}
          profilePicture={profilePicture}
        />
      )}
      <ChatInputView project={project} />
    </MainPage>
  );
};
