import { auth, createCallable } from "@stai/common";
import { StartWebChatDefinition } from "@stai/types";
import { FC, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import { handleError } from "../../Common/helpers/handleError";
import { LoadingView } from "../../Common/views/LoadingView";
import { MainPage } from "../../Main/views/MainPage";

const createWebChatCallable = createCallable(StartWebChatDefinition);

export const CreateChatPage: FC = () => {
  const { projectId, stayId } = useParams<{
    projectId: string;
    stayId?: string;
  }>();

  const [authState] = useAuthState(auth);
  const navigate = useNavigate();
  const browserLanguage = navigator.language ?? "en-GB";

  useEffect(() => {
    if (projectId && authState?.uid) {
      createWebChatCallable({
        projectId,
        language: browserLanguage,
        stayId,
      })
        .then(({ chatId }) => {
          navigate(`/projects/${projectId}/chats/${chatId}`);
        })
        .catch((e) => handleError(e));
    }
  }, [projectId, stayId, authState?.uid]);

  return (
    <MainPage>
      <LoadingView />
    </MainPage>
  );
};
