import { Button, Stack } from "@mui/material";
import { Chat, Project } from "@stai/types";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { usePrevious } from "../../Common/hooks/usePrevious";
import { StorageFile } from "../../Common/types/StorageFile";
import { useMainInit } from "../../Main/hooks/useMainInit";
import { useChatMessageVisibility } from "../hooks/useChatMessageVisibility";
import { useChatMessages } from "../hooks/useChatMessages";
import { ChatMessageView } from "./ChatMessageView";

interface Props {
  project: Project;
  chat: Chat;
  profilePicture?: StorageFile;
}

export const ChatMessageListView: FC<Props> = (props) => {
  const { chat, profilePicture, project } = props;
  const scrollRef = useRef<HTMLDivElement>(null);
  const isInitialScroll = useRef(true);
  const previousScrollPosition = useRef<number>(0);
  const previousScrollHeight = useRef<number>(0);
  const latestChatMessageId = useRef<string>();
  const [isVisible, setIsVisible] = useState(useMainInit.getState().isNewUser);
  const [chatMessages, , , { loadMore, hasMore }] = useChatMessages(
    project.id,
    chat.id
  );

  const prevChatMessages = usePrevious(chatMessages);
  const hasHiddenChatMessages = useChatMessageVisibility(
    (state) => state.hasHiddenChatMessages
  );

  useEffect(() => {
    const prevChatMessagesLength = prevChatMessages?.length ?? 0;
    const chatMessagesLength = chatMessages.length;
    const latestChatMessage = chatMessages[0];
    const hasAddedMessages = Boolean(
      chatMessagesLength - prevChatMessagesLength
    );

    if (typeof window === "undefined") return;
    if (!scrollRef.current) return;
    if (!latestChatMessage) return;
    if (!hasAddedMessages) return;

    // On initial load, scroll to the bottom
    if (isInitialScroll.current) {
      scrollRef.current.scrollIntoView({ behavior: "instant" });
      latestChatMessageId.current = latestChatMessage.id;
      isInitialScroll.current = false;
      setIsVisible(true); // we hide an empty chat to avoid flickering
      return;
    }

    // Scroll to the bottom, when a new message was added
    if (latestChatMessageId.current !== latestChatMessage?.id) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
      latestChatMessageId.current = latestChatMessage?.id;
      return;
    }

    // Stay at the same position, when loading old messages
    if (latestChatMessageId.current === latestChatMessage?.id) {
      const nextScrollHeight = window.document.body.scrollHeight;
      const deviation = nextScrollHeight - previousScrollHeight.current;
      window.scrollTo(0, previousScrollPosition.current + deviation);

      // reset to prevent flickering on a new message
      previousScrollPosition.current = 0;
      previousScrollHeight.current = 0;
      return;
    }
  }, [chatMessages, prevChatMessages]);

  const onClickLoadMore = useCallback(() => {
    if (typeof window === "undefined") return;

    // save current scroll position/height to restore it after loading more messages
    previousScrollPosition.current = window.scrollY;
    previousScrollHeight.current = window.document.body.scrollHeight;

    loadMore();
  }, [loadMore]);

  const isTyping =
    hasHiddenChatMessages || chatMessages[0]?.senderType !== "AI";

  return (
    <Stack
      direction="column-reverse"
      flex={1}
      p={1}
      spacing={2}
      visibility={isVisible ? "visible" : "hidden"}
    >
      <Stack ref={scrollRef} />
      {chatMessages.map((chatMessage, index) => (
        <ChatMessageView
          key={chatMessage.id}
          chatId={chat?.id}
          chatMessage={chatMessage}
          project={project}
          projectProfilePicture={profilePicture}
          isLastMessage={index === 0}
        />
      ))}
      {hasMore && (
        <Button
          variant="text"
          onClick={onClickLoadMore}
          sx={{ alignSelf: "center" }}
        >
          load older messages
        </Button>
      )}
    </Stack>
  );
};
